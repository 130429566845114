var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑忌辰提醒"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"提醒名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"逝世日期类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['date_type', {
            rules: [
              { required: true, message: '请选择日期类型' } ],
            initialValue: '0',
          }]),expression:"['date_type', {\n            rules: [\n              { required: true, message: '请选择日期类型' },\n            ],\n            initialValue: '0',\n          }]"}]},[_c('a-radio',{attrs:{"value":"0"}},[_vm._v("阴历")]),_c('a-radio',{attrs:{"value":"1"}},[_vm._v("阳历")])],1)],1),_c('a-form-item',{attrs:{"label":"周年数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['anniversary', {
            rules: [
              { required: true, message: '请输入周年数' } ]
          }]),expression:"['anniversary', {\n            rules: [\n              { required: true, message: '请输入周年数' },\n            ]\n          }]"}],staticStyle:{"width":"75%"},attrs:{"min":1,"max":999,"step":1,"precision":0},on:{"change":_vm.createContent}})],1),_c('a-form-item',{attrs:{"label":"发送时间"}},[_vm._v(" 逝世日期前 "),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['send_date', {
            rules: [
              { required: true, message: '请设置发送时间' },
              { pattern: /^[1-9]\d*|0$/, message: '请设置发送时间' } ]
          }]),expression:"['send_date', {\n            rules: [\n              { required: true, message: '请设置发送时间' },\n              { pattern: /^[1-9]\\d*|0$/, message: '请设置发送时间' },\n            ]\n          }]"}],staticStyle:{"width":"65%"},attrs:{"min":0,"max":999,"step":1,"precision":0}}),_vm._v(" 天 ")],1),_c('a-form-item',{attrs:{"label":"提醒内容"}},[_c('h5',{staticClass:"content"},[_vm._v("A表示发送提醒的年份，B/C表示逝世月/日，Y表示逝者姓名，Z表示输入的周年数")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content', {
            rules: [
              { required: true, message: '请输入提醒内容' },
              { max: 200, message: '最多200个字符' } ],
            initialValue:'A年B月C日是Y先生(女士)的Z周年忌辰。',
          }]),expression:"['content', {\n            rules: [\n              { required: true, message: '请输入提醒内容' },\n              { max: 200, message: '最多200个字符' },\n            ],\n            initialValue:'A年B月C日是Y先生(女士)的Z周年忌辰。',\n          }]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }