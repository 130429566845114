<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑忌辰提醒"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="提醒名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="逝世日期类型">
          <a-radio-group
            v-decorator="['date_type', {
              rules: [
                { required: true, message: '请选择日期类型' },
              ],
              initialValue: '0',
            }]"
          >
            <a-radio value="0">阴历</a-radio>
            <a-radio value="1">阳历</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="周年数">
          <a-input-number
            style="width: 75%;"
            :min="1"
            :max="999"
            :step="1"
            :precision="0"
            @change="createContent"
            v-decorator="['anniversary', {
              rules: [
                { required: true, message: '请输入周年数' },
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="发送时间">
          逝世日期前
          <a-input-number
            style="width: 65%;"
            :min="0"
            :max="999"
            :step="1"
            :precision="0"
            v-decorator="['send_date', {
              rules: [
                { required: true, message: '请设置发送时间' },
                { pattern: /^[1-9]\d*|0$/, message: '请设置发送时间' },
              ]
            }]"
          />
          天
        </a-form-item>
        <a-form-item label="提醒内容">
          <h5 class="content">A表示发送提醒的年份，B/C表示逝世月/日，Y表示逝者姓名，Z表示输入的周年数</h5>
          <a-textarea
            style="width: 100%;"
            :disabled="true"
            v-decorator="['content', {
              rules: [
                { required: true, message: '请输入提醒内容' },
                { max: 200, message: '最多200个字符' },
              ],
              initialValue:'A年B月C日是Y先生(女士)的Z周年忌辰。',
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateDeathDayRemind } from '@/api/death_day_remind'
import { toChineseNumber } from '@/utils/number_convert_chinese_number'

export default {
  name: 'EditDeathDayRemind',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'death_day_remind' }),
      roleOptions: [],
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.initFormData()
  },
  methods: {
    initFormData() {
      this.data = Object.assign({}, { name: this.record.name, date_type: this.record.date_type, send_date: this.record.send_date, anniversary: this.record.anniversary, content: this.record.content })
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
      })
    },
    createContent() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          content: 'A年B月C日是Y先生(女士)的' +
              (this.form.getFieldValue('anniversary') ? toChineseNumber(this.form.getFieldValue('anniversary')) : 'X') +
              '周年忌辰。'
        })
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateDeathDayRemind(this.record.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
<style lang= "less" scoped>
  .content {
    color: #b1b0b0;
    line-height: 120%;
  }
</style>
